import { ChannelType, MediaModel } from './conversations';
import Config from '../config';
export class AutoAlertKeywordModel {
    constructor() { }
    ID: string;
    Keyword: string;
    PhoneNumberID: string;
    Status: AutoAlertKeyStatus;
    CreatedBy: string | null;
    CreatedDateTime: string;
    IsDeleted: boolean;
}

export interface ConditionModel {
    id: number,
    condition: { Name: string, Value: AutomationRuleConditionTypes },
    value: any[],
    conditionError: string,
    valueError: string
}

export const mapToAutoAlertKeyword = (x: any): AutoAlertKeywordModel => {
    return {
        ID: x.id,
        Keyword: x.keyword,
        PhoneNumberID: x.phoneNumberId,
        Status: x.status as AutoAlertKeyStatus,
        CreatedBy: x.createdBy,
        CreatedDateTime: x.createdDateTime,
        IsDeleted: x.isDeleted
    } as AutoAlertKeywordModel;
}

export class AutoAlertLogsModel {
    constructor() { }
    Number: string;
    CreatedDateTime: string;
    IsSuccess: boolean;
    RuleID: string;
    Keyword: string;
}

export const mapToAutoAlertLogs = (x: any): AutoAlertLogsModel => {
    return {
        Number: x.number,
        CreatedDateTime: x.createdDateTime,
        IsSuccess: x.isSuccess,
        RuleID: x.ruleId,
        Keyword: x.keyword
    } as AutoAlertLogsModel;
}

export class RuleModel {
    constructor() { }
    ID: string;
    Name: string;
    Type: RuleType | -1;
    KeywordID: string;
    Priority: number;
    Status: RuleStatus;
    Message: string;
    IsOneTime: boolean;
    Duration: RuleDuration;
    PhoneNumbers: string[];
    ParentRuleId: string;
    CreatedBy: string;
    CreatedDateTime: string;
    IsLiveToday: boolean;
    Media?: MediaModel;
    IsDeleted: boolean;
}

export class RuleDuration {
    constructor() {
    }
    DurationType: RuleDurationType;
    StartDateTime: number;
    EndDateTime: number;
    Days: RuleDurationDays[];
}

export const mapToRuleDuration = (x: any): RuleDuration => {
    return {
        DurationType: x.durationType as RuleDurationType,
        StartDateTime: x.startDateTime,
        EndDateTime: x.endDateTime,
        Days: x.days
    } as RuleDuration;
}

export const serializeRuleDuration = (x: RuleDuration): any => {
    return {
        durationType: x.DurationType,
        startDateTime: new Date(x.StartDateTime).toISOString(),
        endDateTime: new Date(x.EndDateTime).toISOString(),
        days: x.Days
    } as any;
}

export const mapToAutoAlertRule = (x: any): RuleModel => {
    return {
        ID: x.id,
        Name: x.name,
        Type: x.type as RuleType,
        KeywordID: x.keywordId,
        Priority: x.status === RuleStatus.COMPLETED ? NaN : x.priority,
        Status: x.status as RuleStatus,
        Message: x.message,
        IsOneTime: !x.isRecurring,
        Duration: x.duration ? mapToRuleDuration(x.duration) : undefined,
        PhoneNumbers: x.phoneNumberList,
        ParentRuleId: x.parentRuleId,
        CreatedBy: x.createdBy,
        CreatedDateTime: x.createdDateTime,
        IsLiveToday: x.isLiveToday,
        IsDeleted: x.isDeleted,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
    } as RuleModel;
}

export const serializeAutoAlertRule = (x: RuleModel): any => {
    return {
        id: x.ID,
        name: x.Name,
        type: x.Type,
        keywordId: x.KeywordID,
        priority: Number.isNaN(x.Priority) ? -1 : x.Priority || -1,
        status: x.Status,
        message: x.Message,
        isRecurring: !x.IsOneTime,
        duration: x.Duration ? serializeRuleDuration(x.Duration) : null,
        phoneNumberList: x.PhoneNumbers || [],
        parentRuleId: x.ParentRuleId,
        createdBy: x.CreatedBy,
        createdDateTime: x.CreatedDateTime,
        isLiveToday: x.IsLiveToday,
        isDeleted: x.IsDeleted,
        media: x.Media,
    } as any;
}

export class ChannelAutomatedReply {
    constructor() { }
    Id: string;
    Actions: ChannelAutomatedRepliesActions[];
    AutomationName: string;
    ChannelAccounts: string[];
    ChannelType: ChannelType;
    ConditionStatus: number;
    Conditions: ChannelAutomatedRepliesConditions[];
    CreatedAt: string;
    CreatedBy: string;
    Keywords: string[];
    Priority: number;
    Status: RuleStatus;
    UpdatedAt: string;
    UpdatedBy: string
    TriggerOnce: boolean;
}

export const mapToChannelAutomatedReply = (x: any): ChannelAutomatedReply => {
    return {
        Id: x.id,
        Actions: x.actions.map(x => mapToChannelAutomatedRepliesActions(x)),
        AutomationName: x.automation_name,
        ChannelAccounts: x.channel_accounts,
        ChannelType: x.channel_type as ChannelType,
        ConditionStatus: x.condition_status,
        Conditions: x.conditions.map(x => mapToChannelAutomatedRepliesConditions(x)),
        CreatedAt: x.created_at,
        CreatedBy: x.created_by,
        Keywords: x.keywords,
        Priority: x.priority,
        Status: x.status,
        UpdatedAt: x.updated_at,
        UpdatedBy: x.updated_by,
        TriggerOnce: x.trigger_once,
    }
}

export class ChannelAutomatedRepliesActions {
    constructor() { }
    ActionName: string;
    ActionValue: { Type: string, Value: string, Payload: any }
}

export const mapToChannelAutomatedRepliesActions = (x: any): ChannelAutomatedRepliesActions => {
    return {
        ActionName: x.action_name,
        ActionValue: { Type: x.action_value.type, Value: x.action_value.value, Payload: x.action_value.payload }
    }
}

export class ChannelAutomatedRepliesConditions {
    constructor() { }
    ConditionName: AutomationRuleConditionTypes;
    ConditionValues: string[]
}

export const mapToChannelAutomatedRepliesConditions = (x: any): ChannelAutomatedRepliesConditions => {
    return {
        ConditionName: x.condition_name,
        ConditionValues: x.condition_values
    }
}

export enum RuleDurationDays {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,

}

export enum AutoAlertKeyStatus {
    ACTIVE,
    PAUSED
}

export enum RuleStatus {
    ACTIVE,
    INACTIVE,
    COMPLETED
}

export enum ChannelRuleStatus {
    ACTIVE = 1,
    IN_ACTIVE
}

export enum RuleType {
    AUTO_REPLY,
    KEYWORD,
    BUSINESS_RULES
}

export enum RuleDurationType {
    FIXED_DURATION,
    REPEAT_DAILY,
    REPEAT_WEEKLY,
    REPEAT_MONTHLY,
    ALWAYS,
}

export enum AutomationRuleConditionTypes {
    LOCATION = 1,
    GENDER,
    MESSAGE_ACTIVITY,
    KEYWORDS,
    STATUS,
    BUSINESS_INFORMATION
}
