import { ChannelType } from '../../models/channels/channels.model';
import { AxiosInstance } from '../../helpers/AxiosInstance';
import Config from '../../config';



const getAllChannelAccounts = (orgID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/all`);
};//channel-account

const getChannelAccount = (orgID: string, type: ChannelType, id: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${id}`);
};//channel-acount

const createChannelAccount = (orgID: string, type: ChannelType, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${type}`, data);
};//channel-acount

const updateChannelAccount = (orgID: string, id: string, data: any) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${id}`, data);
};//channel-acount

const deleteChannelAccount = (orgID: string, id: string) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${id}`);
};//channel-acount

const enableChannelAccount = (orgID: string, id: string, userID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${id}/enable?user_id=${userID}`);
};//channel-acount

const disableChannelAccount = (orgID: string, id: string, userID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/account/${orgID}/${id}/disable?user_id=${userID}`);
};//channel-acount

const channelAccountOnlineStatus = ( Participant_Id: string, channel: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/livechat/participant/${Participant_Id}/${channel}/online`);
};

const channelAccountOfflineStatus = ( Participant_Id: string, channel: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/livechat/participant/${Participant_Id}/${channel}/offline`);
};

const getAllChannelOrganisations = () => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/user/organization/all`);
}

const getAllChannelUsers = () => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/user/all`);
}

const addChannelOrganisation = (data: {
    name: string,
    email: string,
    status: number,
    plan_type: number,
    added_by: string
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/user/organization/add`, data);
}

const EditChannelOrganisation = (id: string, data: {
    name: string,
    email: string,
    status: number,
    plan_type: number,
}) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/user/organization/${id}`, data);
}

const CreateBusinessHours = (orgid: string, type: number, data) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/businesshours/${orgid}/create`, data);
}

const UpdateBusinessHours = (orgid: string, type: number, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/businesshours/${orgid}/update`, data);
}

const GetBusinessHours = (orgid: string, type: number) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/businesshours/${orgid}/get`,);
}

const GetBusinessActiveStatus = (orgid: string, type: number) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/businesshours/${orgid}/check/active/`,);
}




export const ChannelService = {
    getAllChannelAccounts,
    createChannelAccount,
    updateChannelAccount,
    getChannelAccount,
    deleteChannelAccount,
    enableChannelAccount,
    disableChannelAccount,
    channelAccountOnlineStatus,
    channelAccountOfflineStatus,
    getAllChannelOrganisations,
    addChannelOrganisation,
    EditChannelOrganisation,
    getAllChannelUsers,
    CreateBusinessHours,
    UpdateBusinessHours,
    GetBusinessHours,
    GetBusinessActiveStatus,
};

